import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export enum StatusCodes {
  PreconditionFailed = 424, //Precondition Failed
  DependencyFailure = 412, //Failed Dependency
  Success = 200,
}
@Injectable({
  providedIn: 'root',
})

export class BackendApiService {
  Endpoint = environment.apiUrl;
  X_API=environment.xapiKey
  reCaptchakey = environment;
  public isLoading = new BehaviorSubject(false);
  private apiData = new BehaviorSubject<any>(null);
  public apiData$ = this.apiData.asObservable();
  private issueDetected = new BehaviorSubject<any>(null);
  public issueDetected$ = this.issueDetected.asObservable();
  visible: boolean;
  public StatusCodes = StatusCodes;

  constructor(private http: HttpClient) {
    this.visible = true;
  }

  // api for validating sessionId
  initiate(data: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('X-API-Key', this.X_API);
    return this.http.post(this.Endpoint + 'initiate', data, { 'headers': headers });
  }

  hideNavbar() { this.visible = false; }

  showNavbar() { this.visible = true; }

  setData(data: any) {
    this.apiData.next(data)
  }

  sendIssueDetected(data: any) {
    this.issueDetected.next(data)
  }

  // api for validating 6 digit pac code
  pacCodeValidation(data: any): Observable<any> {
    return this.http.post(this.Endpoint + 'validate-pac-code', data);
  }

  // api for front, back and selfie documents
  talesapi(data: any): Observable<any> {
    return this.http.post(this.Endpoint + 'scenario', data);
  }

  // api for sending sms in mobile_handoff screen
  sendSMS(data: any): Observable<any> {
    return this.http.post(this.Endpoint + 'send-sms', data, {
      responseType: 'json',
    });
  }

  // api for getting secrets
  getcred(): Observable<any> {
    return this.http.get(this.Endpoint + 'getsecrets');
  }


}


