import { Routes } from '@angular/router';
import { NavbarComponent } from './components/navbar/navbar.component';
import { TechnicalDiffcultiesComponent } from './components/technical-diffculties/technical-diffculties.component';
import { AuthguardService as Authguard } from './services/authguard.service';
import { SessionIDService as SessionId } from './services/session-id.service';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/ekyc',
    pathMatch: 'full',
  },
  {
    path: 'ekyc',
    component: NavbarComponent,
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./components/entrypoint-one/entrypoint-one.component').then(
            (m) => m.EntrypointOneComponent
          ),
        data: { title: 'Rogers Bank - eKYC' },
      },
      {
        path: 'verify_code',
        loadComponent: () =>
          import('./components/entrypoint-two/entrypoint-two.component').then(
            (m) => m.EntrypointTwoComponent
          ),
        // canActivate: [SessionId],
        data: { title: 'Rogers Bank  - eKYC - Verification' },
      },
      {
        path: 'mobile_handoff',
        loadComponent: () =>
          import('./components/mobilehandoff/mobilehandoff.component').then(
            (m) => m.MobilehandoffComponent
          ),
        canActivate: [SessionId],
        data: { title: 'Rogers Bank - eKYC - MobileHandOff' },
      },
      {
        path: 'application_expired',
        loadComponent: () =>
          import(
            './components/application-expired/application-expired.component'
          ).then((m) => m.ApplicationExpiredComponent),
        data: { title: 'Rogers Bank - eKYC - Application Expired' },
      },
      {
        path: 'select_document',
        loadComponent: () =>
          import('./components/select-document/select-document.component').then(
            (m) => m.SelectDocumentComponent
          ),
        canActivate: [Authguard, SessionId],
        data: { title: 'Rogers Bank - eKYC - Select Document' },
      },
      {
        path: 'front_document_capture',
        loadComponent: () =>
          import('./components/front-id-capture/front-id-capture.component').then(
            (m) => m.FrontIdCaptureComponent
          ),
        canActivate: [Authguard, SessionId], canActivateChild:[SessionId],
        data: { title: 'Rogers Bank - eKYC - Front ID Capture' },
      },
      {
        path: 'back_document_capture',
        loadComponent: () =>
          import('./components/back-id-capture/back-id-capture.component').then(
            (m) => m.BackIdCaptureComponent
          ),
        canActivate: [Authguard, SessionId], canActivateChild:[SessionId],
        data: { title: 'Rogers Bank - eKYC - Back ID Capture' },
      },
      {
        path: 'image_issue_detected/:id',
        loadComponent: () =>
          import('./components/image-issue-detected/image-issue-detected.component').then(
            (m) => m.ImageIssueDetectedComponent
          ),
        canActivate: [Authguard, SessionId], canActivateChild:[SessionId],
        data: { title: 'Rogers Bank - eKYC - Image Issue Detected' },
      },
      {
        path: 'selfie_time',
        loadComponent: () =>
          import('./components/selfie-time/selfie-time.component').then(
            (m) => m.SelfieTimeComponent
          ),
        canActivate: [Authguard, SessionId],
        data: { title: 'Rogers Bank - eKYC - Selfie Time' },
      },
      {
        path: 'terms&conditions',
        loadComponent: () =>
          import('./components/terms-conditions/terms-conditions.component').then(
            (m) => m.TermsConditionsComponent
          ),
        data: { title: 'Rogers Bank - eKYC - Terms & Conditions' },
      },
      {
        path: 'application_pending/:status',
        loadComponent: () =>
          import('./components/final-result/final-result.component').then(
            (m) => m.FinalResultComponent
          ),
        data: { title: 'Rogers Bank - eKYC - Application Pending' },
      },
      {
        path: 'unable_to_process_verification/:page',
        loadComponent: () =>
          import(
            './components/failure-verification/failure-verification.component'
          ).then((m) => m.FailureVerificationComponent),
        canActivate: [Authguard, SessionId],
        data: { title: 'Rogers Bank - eKYC - Verification Failure' },
      },
      {
        path: 'sessionout',
        loadComponent: () =>
          import('./components/session-out/session-out.component').then(
            (m) => m.SessionOutComponent
          ),
        data: { title: 'Rogers Bank - eKYC - Session Time Out' },
      },
      {
        path: 'ekyc_completed',
        loadComponent: () =>
          import('./components/ekyc-completed/ekyc-completed.component').then(
            (m) => m.EkycCompletedComponent
          ),
        data: { title: 'Rogers Bank - eKYC - eKYC Completed' },
      },
      {
        path: 'retake_photos/:error',
        loadComponent: () =>
          import('./components/retake-photos/retake-photos.component').then(
            (m) => m.RetakePhotosComponent
          ),
        canActivate: [Authguard, SessionId],
        data: { title: 'Rogers Bank - eKYC - Retake Photos' },
      },
      {
        path: 'camera_permission_failed',
        loadComponent: () =>
          import('./components/camera-permission/camera-permission.component').then(
            (m) => m.CameraPermissionComponent
          ),
        canActivate: [Authguard, SessionId],
        data: { title: 'Rogers Bank - eKYC - Camera Permission Failed' },
      },
      {
        path: 'document_capture_failure',
        loadComponent: () =>
          import('./components/qrcode-newdevice/qrcode-newdevice.component').then(
            (m) => m.QrcodeNewdeviceComponent
          ),
        canActivate: [Authguard, SessionId],
        data: { title: 'Rogers Bank - eKYC - Document Capture Failed' },
      },
      {
        path: 'primary_check_failure/:error',
        loadComponent: () =>
          import('./components/primary-check-fail/primary-check-fail.component').then(
            (m) => m.PrimaryCheckFailComponent
          ),
        canActivate: [Authguard, SessionId],
        data: { title: 'Rogers Bank - eKYC - Primary Check Fail' },
      },
      {
        path: 'retail',
        loadComponent: () =>
          import('./components/retail-channel/retail-channel.component').then(
            (m) => m.RetailChannelComponent
          ),
        data: { title: 'Rogers Bank - eKYC - Retail Channel' },
      },
      {
        path: '**',
        component: TechnicalDiffcultiesComponent,
        data: { title: 'Rogers Bank - eKYC - Technical Error' },
      },
    ],
  },

  {
    path: '**',
    component: NavbarComponent,
    children: [
      {
        path: '**',
        component: TechnicalDiffcultiesComponent,
      },
    ],
  },
];



